.custom-table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
  height: 100%;
  overflow-y: scroll;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.custom-table th,
.custom-table td {
  border: 1px solid #303375;
  text-align: left;
  padding: 8px;
}

.custom-table th {
  background-color: #3e4396;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #1f2a40;
}

.date-picker-span {
  display: block;
  margin: 0.5rem 0 0.3rem;
}
