@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');

html, body, #root, .app, .content { 
  min-height: 100vh;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
}

.app {
  display: flex;
  position: relative;
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-track:hover {
  background: #555;
}

.ps-sidebar-root {
  border: none !important;
}

.ps-sidebar-container{
  height: 100vh !important; 
  background-color: inherit !important;
} 

.fullwidth-datepicker {
  width: 100%;
}